import { el } from 'redom';
import Language from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Language';
import InterfaceOriginal from '../../../../../../../../../../../vendor/integer-net/magento2-cookieconsent/view/frontend/web/js/cookieconsent/src/lib/Interface';

export default class Interface extends InterfaceOriginal
{
    buildBar() {
        const defaultElement = super.buildBar(arguments);
        const textElement = defaultElement.querySelector('.cc-text');

        textElement.prepend(
            el('div.cc-title', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barTitle'))
        );

        return defaultElement;
    }
}
